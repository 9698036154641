<template>
  <v-container>
    <div v-if="!fetchingContent" class="mb-2" style="font-size: 20px">
        Notes
    </div>
    <!-- <PageTitleSearch
      :page-title="!$language ? 'Notes' : 'লেকচার'"
      :placeholder="
        !$language
          ? 'Search By Title'
          : 'লেকচারের শিরোনাম দিয়ে সার্চ করো'
      "
      @searchElenent="searchLecture"
    ></PageTitleSearch> -->
    <v-container>
      <v-row>
        <v-col v-if="fetchingContent">
          <template>
            <div id="loading-wrapper">
              <div id="loading-text">LOADING</div>
              <div id="loading-content"></div>
            </div>
          </template>
        </v-col>
        <v-col v-else-if="noLectureCondition">
          <no-content-component
            :message="noLectureMessage"
          ></no-content-component>
        </v-col>
        <v-col v-else :class="classes.container" class="bg-white rounded-lg"  style="min-height: 75vh;">
          <course-sections 
            :sections="sections"
            @onSelect="selectSection"
          />
          <div
            style="max-height: 60vh"
            class="mt-2 overflow-x-hidden overflow-y-auto"
          >
            <course-organization-expansion-panel
              :isNote="true"
              :isTest="false"
              :selected="selected"
              :course-id="courseId"
            />
              <!-- :owner-id="ownerId" -->
            </div>  
        </v-col>
        
        <!-- <v-col
          v-else
          v-for="(lecture, i) in filterLectures"
          :key="i"
          :cols="colsNum"
        >
          <m-lecture-list-item
            :lecture="lecture"
            @download="download(lecture.object_id)"
          ></m-lecture-list-item>
        </v-col> -->
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import lectureService from "#ef/lecture/services/LectureService";
// import PageTitleSearch from "@ef/global/components/PageTitleSearch.vue"; 
import CourseOrganizationExpansionPanel from "#ecf/course-design/components/CourseOrganization/CourseOrganizationExpansionPanel.vue";
import CourseSections from "#ecf/course-design/components/CourseOrganization/CourseSections.vue";
// import MLectureListItem from "#ef/lecture/components/MLectureListItem";
import { CourseDesignUtils } from "#ecf/course-design/mixins";
import NoContentComponent from "/global/components/NoContentComponent";
import Underline from "@tiptap/extension-underline";

export default {
  name: "MLectureList",
  // MLectureListItem
  // PageTitleSearch
  components: { NoContentComponent,CourseOrganizationExpansionPanel,CourseSections },
  mixins: [CourseDesignUtils],
  data() {
    return {
      saving: false,
      batch_id: undefined,
      lectures: [],
      searchInput: "",
      fetchingContent: undefined,
      selected: undefined,
      courseId: undefined,
      loading:false,
    };
  },
  computed: {
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    colsNum() {
      return this.currentWidth < 800
        ? 12
        : this.currentWidth < 1280
        ? 12
        : this.currentWidth < 1920
        ? 6
        : 4;
    },
    // filterLectures() {
    //   return this.lectures.filter((lecture) =>
    //     lecture.title.toLowerCase().match(this.searchInput.toLowerCase())
    //   );
    // },
    modules() {
        return this.$store.state.studentCourseDesignDashboard.courseModules[this.$ielts_course_id]
    },
   
    // noLectureCondition() {
    //   return this.lectures === undefined || this.lectures.length == 0;
    // },
    noLectureCondition() {
      return this.sections === undefined || this.sections.length == 0;
    },
    noLectureMessage() {
      return !this.$language
        ? "You currently have no lectures"
        : "বর্তমানে আপনার কোনো লেকচার সমূহ নেই";
    },
    classes() {
      return {
        container: `pa-${this.breakPointValues(3, 3, 6, 6)}`,
        slideItem: {
          outer: {
            true: "primary white--text",
            false: "bg-white border border-solid border-primary primary--text"
          },
          inner: {
            true: "white primary--text",
            false: "primary white--text"
          }
        }
      };
    },
    sections() {
        return this.$store.state.studentCourseDesignDashboard.courseSections[
          this.$ielts_course_id
        ];
    },
    //  selectedSection() {
    //   if (this.selected === undefined) return [];
    //   return this.sections[this.selected].contents;
    // },
    // contents() {
    //   let selectedLectures = [];
    //     this.selectedSection.forEach((moduleId)=> {
    //       let allModuleContents = this.$store.state.studentCourseDesignDashboard.moduleContents[this.$ielts_course_id];
    //       let module = this.$store.state.studentCourseDesignDashboard.courseModules[this.$ielts_course_id][moduleId];
    //       let moduleContentIds = [];
    //       module.contents.forEach(content => {
    //         moduleContentIds.push(content.id);
    //       });
    //       moduleContentIds.map((id)=> {
    //         allModuleContents[id].moduleId = moduleId
    //         selectedLectures.push(allModuleContents[id]);
    //       })
    //     })
    //     return selectedLectures;
    // },
    
  },
  async created() {
    this.batch_id = this.$ielts_batch_id;
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    // await this.fetchLectures()
    await this.retriveStudentsCourseDesign()

  },
  methods: {
    async retriveStudentsCourseDesign() {
      try {
        this.fetchingContent = true;
        this.courseId = this.$ielts_course_id;
        let studentId = this.$store.state.user.user.user_id;
        await this.$store.dispatch(
          "studentCourseDesignDashboard/getCourseDesign",
          {
            courseId:this.courseId,
            studentId
          }
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.fetchingContent = false;
      }
    },
    // async fetchLectures() {
    //   try {
    //     this.fetchingContent = true;
    //     this.lectures = await lectureService.find(this.batch_id);
    //     console.log(this.lectures)
    //   } catch (e) {
    //     this.$root.$emit('alert', [undefined, e.message]);
    //   } finally {
    //     this.fetchingContent = false;
    //   }
    // },
    // searchLecture(searchInput) {
    //   this.searchInput = searchInput;
    // },
    async download(lecture_id) {
      try {
        const lecture = await lectureService.get(lecture_id, this.batch_id);
        window.open(lecture.url);
      } catch (e) {
        this.$root.$emit("alert", ["Alert", e.toString()]);
      }
    },
    moduleName(moduleId) {
      return this.modules[moduleId].title;
    },
    selectSection(value) {
      this.selected = value
    }
  },
};
</script>
