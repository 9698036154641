var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      !_vm.fetchingContent
        ? _c(
            "div",
            { staticClass: "mb-2", staticStyle: { "font-size": "20px" } },
            [_vm._v(" Notes ")]
          )
        : _vm._e(),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _vm.fetchingContent
                ? _c(
                    "v-col",
                    [
                      [
                        _c("div", { attrs: { id: "loading-wrapper" } }, [
                          _c("div", { attrs: { id: "loading-text" } }, [
                            _vm._v("LOADING"),
                          ]),
                          _c("div", { attrs: { id: "loading-content" } }),
                        ]),
                      ],
                    ],
                    2
                  )
                : _vm.noLectureCondition
                ? _c(
                    "v-col",
                    [
                      _c("no-content-component", {
                        attrs: { message: _vm.noLectureMessage },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    {
                      staticClass: "bg-white rounded-lg",
                      class: _vm.classes.container,
                      staticStyle: { "min-height": "75vh" },
                    },
                    [
                      _c("course-sections", {
                        attrs: { sections: _vm.sections },
                        on: { onSelect: _vm.selectSection },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "mt-2 overflow-x-hidden overflow-y-auto",
                          staticStyle: { "max-height": "60vh" },
                        },
                        [
                          _c("course-organization-expansion-panel", {
                            attrs: {
                              isNote: true,
                              isTest: false,
                              selected: _vm.selected,
                              "course-id": _vm.courseId,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }